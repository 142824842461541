import { render, staticRenderFns } from "./as-worker-form.vue?vue&type=template&id=38efcb7a&scoped=true&"
import script from "./as-worker-form.vue?vue&type=script&lang=js&"
export * from "./as-worker-form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38efcb7a",
  null
  
)

export default component.exports