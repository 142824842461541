<template>
	<div>
		<b-modal v-model="workerInfo" title="Worker information" size="lg" hide-footer centered>
			<Details :worker="currentWorker" />
		</b-modal>

		<SaveAsWorkerForm v-if="currentWorker" :is-skilled="currentWorker.is_skilled === 1" @done="saveAsWorker" />

		<base-table :items="items" :headers="headers" table-top paginate>
			<template #cell(actions)="data">
				<b-button variant="outline-secondary" class="cursor-pointer mr-50" size="sm" @click="showWorkerInfo(data.item)">
					<span class="align-middle">View </span>
				</b-button>
				<loading-btn
					v-if="!data.item.is_worker"
					v-b-modal.as-worker-modal
					variant="outline-secondary"
					class="cursor-pointer"
					size="sm"
					:loading="loading"
					@click="mapToSubProject(data.item)"
				>
					<span class="align-middle">Add as workers</span>
				</loading-btn>
			</template>
		</base-table>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { BButton, BModal } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import LoadingBtn from '@/components/LoadingBtn.vue';
import store from '@/store';
import Details from './Details.vue';
import SaveAsWorkerForm from './as-worker-form.vue';

export default {
	name: 'AbleToWork',

	components: { BaseTable, BButton, BModal, Details, SaveAsWorkerForm, LoadingBtn },

	data: () => ({
		items: [],

		headers: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'full_name', sortable: true },
			{ key: 'phone', sortable: true },
			{ key: 'identity_type', sortable: true },
			{ key: 'identity_number', sortable: true },
			{ key: 'gender' },
			{ key: 'actions' },
		],

		beneficiaries: [],

		loading: false,
		workerInfo: false,
		currentWorker: undefined,
	}),

	computed: {
		modalTitle() {
			return this.currentWorker?.name;
		},

		...mapState('auth', ['user']),
	},

	async created() {
		this.items = await store.dispatch('ableToWork/getWorkers', this.$route.params.id);
	},

	methods: {
		showWorkerInfo(worker) {
			this.currentWorker = worker;
			this.workerInfo = true;
		},

		mapIdentity(identity) {
			if (identity.trim() === 'بطاقة شخصية') return 1;
			if (identity.trim() === 'بطاقة عائلية') return 2;
			if (identity.trim() === 'جواز سفر') return 3;
			if (identity.trim() === 'رخصة قيادة') return 4;
			return 5;
		},

		mapWork(work) {
			if (work.trim() === 'عامل') return 1;
			if (work.trim() === 'طالب') return 2;
			if (work.trim() === 'نازح') return 3;
			if (work.trim() === 'موظف') return 4;
			return 5;
		},

		mapSkill(skill) {
			if (skill.trim() === 'سباك') return 1;
			if (skill.trim() === 'نجار') return 2;
			if (skill.trim() === 'حداد') return 3;
			return 4;
		},

		mapEdu(edu) {
			if (edu.trim() === 'ثانوي') return 1;
			if (edu.trim() === 'جامعي') return 2;
			if (edu.trim() === 'خريج') return 3;
			return 4;
		},

		mapLiving(living) {
			if (living.trim() === 'مقيم') return 1;
			if (living.trim() === 'نازح') return 2;
			if (living.trim() === 'عائد') return 3;
			if (living.trim() === 'خارج المنطقة') return 4;
			return 4;
		},

		mapMaritalStatus(status) {
			if (status.trim() === 'عازب') return 1;
			if (status.trim() === 'متزوج') return 2;
			if (status.trim() === 'ارمل') return 3;
			return 4;
		},

		mapGender(edu) {
			if (edu.trim() === 'ذكر') return 1;
			if (edu.trim() === 'انثى') return 2;
		},

		mapIsSkilled(val) {
			if (val.trim() === 'نعم') return 1;
			if (val.trim() === 'لا') return 2;
		},

		mapToSubProject(worker) {
			this.currentWorker = {
				...worker,
				created_by: this.user.id,
				identity_type: this.mapIdentity(worker.identity_type),
				living_conditions: this.mapLiving(worker.living_conditions),
				highest_education_level: this.mapEdu(worker.highest_education_level),
				work_type: this.mapWork(worker.work_type),
				is_skilled: this.mapIsSkilled(worker.is_skilled),
				skill_type: this.mapSkill(worker.skill_type),
				gender: this.mapGender(worker.gender),
				marital_status: this.mapMaritalStatus(worker.marital_status),
				migration_state: 1,
			};
		},

		async saveAsWorker(data) {
			this.loading = true;

			try {
				this.currentWorker.project_id = data.subProjectId;
				if (this.currentWorker.is_skilled) this.currentWorker.item_id = data.itemId;

				await store.dispatch('workers/addWorker', {
					data: [this.currentWorker],
				});
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
