<template>
	<b-list-group flush>
		<b-row>
			<b-col v-for="(key, i) in keys" :key="i" :data-key="i" cols="12" md="6">
				<b-list-group-item
					class="d-flex justify-content-between border-left-0 border-right-0"
					:class="{ 'border-bottom-0': i !== 16 || i !== 15 }"
				>
					<div class="">{{ startCase(key) }}</div>
					<div class="font-weight-bold">{{ worker[key] }}</div>
				</b-list-group-item>
			</b-col>
		</b-row>
	</b-list-group>
</template>

<script>
import startCase from 'lodash.startcase';
import { BListGroup, BListGroupItem, BRow, BCol } from 'bootstrap-vue';

export default {
	name: 'WorkerDetails',

	components: { BListGroup, BListGroupItem, BRow, BCol },

	props: {
		worker: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		title: '',
	}),

	computed: {
		keys() {
			return Object.keys(this.worker).filter((k) => !['is_worker'].includes(k));
		},
	},

	methods: {
		startCase,

		getGender(gender) {
			return gender === 1 ? 'ذكر' : 'انثى';
		},
	},
};
</script>

<style lang="scss" scoped></style>
