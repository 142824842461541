<template>
	<div class="">
		<b-modal
			id="as-worker-modal"
			ref="asWorkerModal"
			title="Save as worker"
			ok-title="Save"
			centered
			cancel-variant="outline-secondary"
			@hidden="resetModal"
			@ok="handleOk"
		>
			<validation-observer v-if="!loading" ref="formRules">
				<b-form>
					<b-row>
						<!-- Sub project -->
						<b-col cols="12">
							<b-form-group label="Sub project" label-for="subProjectId">
								<validation-provider #default="{ errors }" name="Sub project" rules="required">
									<v-select
										id="subProjectId"
										v-model="subProjectId"
										:clearable="false"
										:filterable="false"
										:searchable="false"
										:options="subProjectsList"
										:reduce="(o) => o.id"
										:state="errors.length > 0 ? false : null"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<template v-if="isSkilled">
							<!-- Main item -->
							<b-col v-show="subProjectId" cols="12">
								<b-form-group label="Main item" label-for="itemId">
									<validation-provider #default="{ errors }" name="Main item" rules="required">
										<v-select
											id="itemId"
											v-model="itemId"
											:clearable="false"
											:filterable="false"
											:searchable="false"
											:options="items"
											:reduce="(o) => o.id"
											:state="errors.length > 0 ? false : null"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>

							<!-- Sub item -->
							<b-col v-show="itemId" cols="12">
								<b-form-group label="Sub item" label-for="subItemId">
									<validation-provider #default="{ errors }" name="Sub item" rules="required">
										<v-select
											id="subItemId"
											v-model="subItemId"
											:clearable="false"
											:filterable="false"
											:searchable="false"
											:options="subItems"
											:reduce="(o) => o.id"
											:state="errors.length > 0 ? false : null"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
						</template>
					</b-row>
				</b-form>
			</validation-observer>

			<div v-else class="">
				<div class="text-center text-primary mt-3 mb-2">
					<b-spinner class="align-middle" style="width: 3rem; height: 3rem;"></b-spinner>
					<span class="d-block mt-1">Loading...</span>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { BModal, BForm, BCol, BRow, BFormGroup, BSpinner } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import { required } from '@validations';
import store from '@/store';

export default {
	name: 'SaveAsWorkerForm',

	components: { BModal, BForm, BCol, BRow, BFormGroup, BSpinner, ValidationProvider, ValidationObserver, vSelect },

	props: {
		isSkilled: Boolean,
	},

	data: () => ({
		required,

		subProjectId: '',
		subProjects: [],
		subProjectsList: [],

		itemId: '',
		items: [],

		subItemId: '',
		subItems: [],

		loading: false,
	}),

	watch: {
		subProjectId(val) {
			if (val) {
				this.items = this.subProjects
					.find((p) => p.id === val)
					?.items.map((i) => ({
						id: i.item_id,
						label: i.name,
					}));
			}
		},

		itemId(val) {
			if (val) {
				this.subItems = this.subProjects
					.find((p) => p.id === this.subProjectId)
					?.sub_items.filter((i) => i.item_id === val)
					?.map((i) => ({
						id: i.sub_item_id,
						label: i.name,
					}));
			}
		},
	},

	mounted() {
		this.loadSubProjects();
	},

	methods: {
		resetModal() {
			this.subProjectId = '';
			this.itemId = '';
		},

		async loadSubProjects() {
			this.loading = true;

			try {
				const data = await store.dispatch('subProjects/getSubProjects', this.$route.params.id);
				this.subProjects = data;
				this.subProjectsList = data.map((p) => ({
					id: p.id,
					label: p.name,
				}));
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = false;
			}
		},

		async handleOk(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();

			const success = await this.$refs.formRules.validate();
			if (!success) return;

			// Hide the modal manually
			this.$nextTick(() => {
				this.$refs.asWorkerModal.toggle();
			});

			this.$emit('done', { subProjectId: this.subProjectId, itemId: this.subItemId });
		},
	},
};
</script>

<style lang="scss" scoped></style>
